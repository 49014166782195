












































































































































import { Component, Prop, Watch, Mixins, PropSync } from 'vue-property-decorator'
import GetStoreMixin from '@/mixins/GetStore'
import { projectNamespace } from '@store/project'
import { commonNamespace } from '@store/common'
import { ProjectActionTypes } from '@store/project/Types'
import { Role } from '@store/common/Interface'
import { ElForm } from 'element-ui/types/form'
import { Paginate, RequestParams } from '@/store/interfaces'
import { IDocument } from '@/store/documents/Interface'
import { LegalPerson, UsersState } from '@/store/users/interfaces'
import { namespace } from 'vuex-class'
import { UsersActions } from '@/store/users/types'
const NSUsers = namespace('usersModule')

interface Form {
  projectId: number | null
  fullName: string
  position: string
  roleId: number | null
  fileList: any[]
  documentIds: number[]
  legalPersonId?: number | undefined
}

@Component({
  name: 'DialogAddIndividualWithLegal',

  components: {
    'v-dialog-choose-file': () => import('@/components/dialogs/DialogChooseFile.vue'),
  },
})
export default class DialogAddIndividualWithLegalComponent extends Mixins(GetStoreMixin) {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop(Number) private legalPersonId!: number | undefined

  @commonNamespace.State('rolesNatural')
  private rolesNatural!: Role[]

  @projectNamespace.Action(ProjectActionTypes.A_ADD_INDIVIDUAL)
  private addIndividualPerson!: (person: Form) => Promise<any>

  @NSUsers.Action(UsersActions.A_FETCH_FILTERED_LEGAL_PERSONS)
  private fetchLegalPersons!: (params?: RequestParams) => Promise<Paginate<LegalPerson>>

  private isExpectation: boolean = false

  private visibleDialogChooseFile = false

  private form: Form = {
    projectId: null,
    fullName: '',
    position: '',
    roleId: null,
    fileList: [],
    documentIds: [],
    legalPersonId: this.legalPersonId,
  }

  private legalPersons: LegalPerson[] = []

  private rules: any = {
    fullName: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    position: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    roleId: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  private created() {
    this.fetchLegalPersons({ projectId: this.selectedProject, count: 5 }).then(legalPersons => {
      this.legalPersons = legalPersons.data
    })
  }

  private mounted() {
    this.form.projectId = this.selectedProject
  }

  private searchLegalPersons(query: string) {
    this.fetchLegalPersons({ projectId: this.selectedProject, count: 5, search: query }).then(legalPersons => {
      this.legalPersons = legalPersons.data
    })
  }

  private add() {
    const form = this.$refs.form as ElForm

    form.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.addIndividualPerson(this.form)
          .then(data => {
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })

            this.isExpectation = false
            this._visible = false

            form.resetFields()
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      }
    })
  }

  private handleChooseFile(doc: IDocument) {
    this.form.fileList.push({ name: doc.filename, size: null, raw: null })
    this.form.documentIds.push(doc.id)
  }

  private handleAddFile(file, fileList) {
    this.form.fileList.push({ ...file })
  }

  private handleDelFile(uid) {
    const index = this.form.fileList.findIndex(file => file.uid === uid)
    this.form.fileList.splice(index, 1)
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    this._visible = false

    form.resetFields()
  }
}
