// Core
import { Component, Vue } from 'vue-property-decorator';

// Store
import { userNamespace } from '@store/user';

@Component
export default class GetStoreMixin extends Vue {
  @userNamespace.State('selectedProject') public selectedProject!: number;
}
